/* eslint-disable @next/next/no-before-interactive-script-outside-document */
/* eslint-disable @next/next/no-page-custom-font */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @next/next/no-script-component-in-head */
'use client'
import Script from 'next/script'
import TagManager from 'react-gtm-module'
import {useEffect} from 'react'
import {hotjar} from 'react-hotjar'
import Head from 'next/head'
import Image from 'next/image'

const TAGMANAGER_ARGS = {
  gtmId: 'GTM-TNP5ZGJ',
}

export default function LayoutComponent({children}: {children: any}) {
  useEffect(() => {
    hotjar.initialize({id: 3379914, sv: 6})
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'ti87d1s7',
    }
    TagManager.initialize(TAGMANAGER_ARGS)
  }, [])

  return (
    <html lang="en">
      <Head>
        {/* <!-- Google Tag Manager --> */}
        <Script strategy="lazyOnload">
          {
            function (w: any, d: any, s: any, l: any, i: any) {
              w[l] = w[l] || []
              w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : ''
              j.async = true
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
              f.parentNode.insertBefore(j, f)
            } as any
          }
          (window,document,'script','dataLayer','GTM-TNP5ZGJ');
        </Script>
        {/* <!-- End Google Tag Manager --> */}
        <meta name="viewport" content="width=device-width, initial-scale=1 ,shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />

        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png"></link>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"></link>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"></link>
        <link rel="icon" type="image/x-icon" href="/favicon/favicon.ico"></link>
        <link rel="manifest" href="/manifest.json"></link>
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap"
          rel="stylesheet"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Head>
      <body className="layout">
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-TNP5ZGJ"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>`,
          }}
        />
        {/* <!-- End Google Tag Manager (noscript) --> */}

        {/* <!-- Linkedin Insight Tag --> */}
        <Script type="text/javascript">
          {`
          _linkedin_partner_id = "5375913"; 
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; 
          window._linkedin_data_partner_ids.push(_linkedin_partner_id); 
        `}
        </Script>
        <Script type="text/javascript">
          {`
          (function(l) { 
            if (!l){
              window.lintrk = function (a, b) { 
                window.lintrk.q.push([a, b]) 
              }; 
              window.lintrk.q=[]
            } 
            var s = document.getElementsByTagName("script")[0]; 
            var b = document.createElement("script"); 
            b.type = "text/javascript";
            b.async = true; 
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; 
            s.parentNode.insertBefore(b, s);
          })(window.lintrk); 
        `}
        </Script>

        {/* <!-- Microsoft clarity script --> */}
        <Script type="text/javascript">
          {`
          if (%NODE_ENV% === 'production') {
            (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "nesz3jtv6q");
          }
          `}
        </Script>

        {/* <!-- Pixel code script --> */}
        <Script
          id="vtag-ai-js"
          strategy="lazyOnload"
          src="https://r2.leadsy.ai/tag.js"
          data-pid="6vggDiV1d8OGX6fv"
          data-version="062024"
        />
        <noscript>
          <Image
            height="1"
            width="1"
            style={{display: 'none'}}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=5375913&fmt=gif"
          />
        </noscript>
        {/* <!-- End Linkedin Insight Tag --> */}

        {/* {exceptions.includes(pathname) ? '' : <Navbar />} */}
        {children}
        {/* {exceptions.includes(pathname) ? '' : <Footer />} */}
        {/* <!-- Start of Intercom Embed Code --> */}
        {/* <Script
          defer
          type="text/javascript"
          src={`https://widget.intercom.io/widget/ti87d1s7`}
        ></Script>{' '} */}
        {/* <!-- End of Intercom Embed Code --> */}
        {/* <!-- Start of HubSpot Embed Code --> */}
        {/* <Script
          type="text/javascript"
          id="hs-script-loader"
          src="//js-eu1.hs-scripts.com/26178692.js"
          strategy="beforeInteractive"
        ></Script> */}
        {/* <!-- End of HubSpot Embed Code --> */}
        {/* hubspot form code */}
        {/* <Script
          charSet="utf-8"
          type="text/javascript"
          src="//js.hsforms.net/forms/v2.js"
          strategy="beforeInteractive"
        ></Script> */}
      </body>
    </html>
  )
}
